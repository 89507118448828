<template>
  <div class="box">
    <div class="header">
      <el-button
        style="width: 115px; height: 34px; margin-top: 50px"
        @click="equity"
        >权益编辑</el-button
      >
      <!-- 金币充值 -->
      <el-dialog title="权益升级" :visible.sync="quanyi" width="60%" center>
        <div class="da" >
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="机构名称">
              <el-input v-model="orgName" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="用户等级">
              <el-select placeholder="活动区域">
                <el-option label="区域一" value="shanghai"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="div">
            <p style="width:100px">功能</p>
            <p style="width:112px;">二手商用车快估</p>
            <p style="width:74px;">车型车价</p>
            <p style="width:74px;">登记状态</p>
            <p style="width:74px;">行业主体</p>
            <p style="width:74px;">关注主体</p>
            <p style="width:74px;">关注登记</p>
            <p style="width:74px;">销量数据</p>
            <p style="width:90px;">子账号(个)</p>
          </div>
          <div class="input">
            <p style="width:100px">增加权益值</p>
            <p style="width:112px;"><input type="text" style="width:50px;"></p>
            <p style="width:74px;"><input type="text" style="width:50px;"></p>
            <p style="width:74px;"><input type="text" style="width:50px;"></p>
            <p style="width:74px;"><input type="text" style="width:50px;"></p>
            <p style="width:74px;"><input type="text" style="width:50px;"></p>
            <p style="width:74px;"><input type="text" style="width:50px;"></p>
            <p style="width:74px;">\</p>
            <p style="width:90px;"><input type="text" style="width:50px;"></p>
          </div>
              <div class="inputa">
            <p style="width:100px">增加月上限</p>
            <p style="width:112px;"><input type="text" style="width:50px;"></p>
            <p style="width:74px;"><input type="text" style="width:50px;"></p>
            <p style="width:74px;"><input type="text" style="width:50px;"></p>
            <p style="width:74px;"><input type="text" style="width:50px;"></p>
            <p style="width:74px;"><input type="text" style="width:50px;"></p>
            <p style="width:74px;"><input type="text" style="width:50px;"></p>
            <p style="width:74px;">\</p>
            <p style="width:90px;">\</p>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="quanyi = false">取 消</el-button>
          <el-button type="primary" @click="quanyique">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      labelPosition: "top",
      quanyi: false,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
   async  equity() {
      this.quanyi = true;
    },
    async quanyique(){
        this.quanyi=false;
    }
  },
};
</script>

<style scoped lang="less">
.box {
  width: 100%;
  .header {
    width: 1000px;
    height: 142px;
    background: #ffffff;
    box-shadow: 0px 0px 10px #bfdff7;
    opacity: 1;
    border-radius: 10px;
  }
}
/deep/.el-dialog__header {
  background-color: #1883e3;
}
// .da{
//     margin :0 auto;
// }
.div {
  width: 850px;
  height: 50px;
//   height: 500px;
//   background: #000;
  p {
    float: left;
    text-align: center;
    // width: 112px;
    // height: 22px;
    // font-size: 16px;
    // font-family: PingFang SC;
    // font-weight: 400;
    // line-height: 22px;
    color: #666666;
    // opacity: 1;
    margin-left:10px;
  }
}
.input {
  width: 850px;
   height: 50px;

  p {
    float: left;
    text-align: center;
    // width: 112px;
    // height: 22px;
    // font-size: 16px;
    // font-family: PingFang SC;
    // font-weight: 400;
    // line-height: 22px;
    color: #666666;
    // opacity: 1;
    margin-left:10px;
  }
}
.inputa {
  width: 850px;
   height: 50px;
  p {
    float: left;
    text-align: center;
    // width: 112px;
    // height: 22px;
    // font-size: 16px;
    // font-family: PingFang SC;
    // font-weight: 400;
    // line-height: 22px;
    color: #666666;
    // opacity: 1;
    margin-left:10px;
  }
}
</style>
